<template>
  <PageContainer
    :title="pageContainerConfig.title"
    :back="pageContainerConfig.back"
    :submit="pageContainerConfig.submit"
  >
    <template v-slot:content>
      <template v-if="loading">
        <Skeleton width="100%" height="240px" />

        <div class="segment-content">
          <Skeleton
            width="100%"
            height="126px"
            margin="32px 0"
            borderRadius="8px"
          />
          <Skeleton
            width="100%"
            height="50px"
            margin="8px 0"
            borderRadius="8px"
          />

          <div class="margin-y" v-for="(v, i) in 2" :key="i">
            <Skeleton
              width="190px"
              height="30px"
              borderRadius="8px"
              margin="16px 0"
            />

            <Skeleton
              width="100%"
              height="50px"
              borderRadius="8px"
              margin="0"
            />
          </div>

          <Skeleton
            width="100%"
            height="150px"
            margin="8px 0"
            borderRadius="8px"
          />
        </div>
      </template>

      <template v-if="program && !loading && specialists">
        <div class="program-detail page-segment padding-all-zero">
          <div class="program-detail-cover">
            <img
              class="program-detail-cover-img"
              :src="program.image"
              :alt="program?.name"
            />
          </div>

          <div
            class="page-segment-content program-detail-container"
            :class="responsiveClass('padding-x', '')"
          >
            <!-- name -->
            <div
              class="heading margin-top-lg margin-bottom-sm"
              v-text="program.name"
            />
            <!-- subtitle -->
            <div
              class="body text-purple-110 margin-bottom-xs"
              v-text="program.subtitle"
            />

            <!-- description -->
            <div class="body margin-bottom-lg" v-text="program.content" />

            <!-- stats -->
            <div class="program-detail-items" v-if="program">
              <StatItem
                icon="icon-video-outline-off"
                :title="$translations.programs.detail.stats.modality"
                :sub-title="program.modality"
              />

              <StatItem
                icon="icon-money-off"
                :title="$translations.programs.detail.stats.price"
                :sub-title="programPrice"
              />
              <StatItem
                icon="icon-appointment-off"
                :title="$translations.programs.detail.stats.sessions"
                :sub-title="program.session"
              />

              <StatItem
                icon="icon-timer-off"
                :title="$translations.programs.detail.stats.duration"
                :sub-title="program.duration"
              />
            </div>
          </div>
        </div>

        <!-- LEARNING -->
        <div class="page-segment">
          <div class="page-segment-content program-detail-learning">
            <div
              class="heading-small margin-bottom-xs"
              v-text="$translations.programs.detail.learning"
            />
            <div
              class="program-detail-learning-content"
              v-for="(text, index) of program.programContent"
              :key="index"
            >
              <div class="heading-medium icon-checkmark-circle-on text-green" />
              <div class="body" v-text="text" />
            </div>

            <AddiBanner
              class="margin-top-lg"
              v-if="allowedInCountry('CO')"
              type="PROGRAM"
              :id="program.id"
              :version="2"
              @clicked="handlerCheckout('ADDI')"
            />
          </div>
        </div>

        <!-- DESCRIPTION -->
        <div class="page-segment" v-if="program.description">
          <div class="page-segment-content">
            <div
              class="heading-small margin-bottom-xs"
              v-text="$translations.programs.detail.description"
            />

            <!-- description -->
            <div class="body">
              {{ descriptionMoreAndLess }}
            </div>
            <div
              class="content-title text-right cursor-pointer"
              v-text="getText"
              v-if="program.description.length > 200"
              @click="toggleDescription = !toggleDescription"
            />
          </div>
        </div>

        <!-- SPECIALISTS -->
        <div class="page-segment">
          <div class="page-segment-content program-detail-specialists">
            <div
              class="heading-small"
              v-text="$translations.programs.detail.specialists.title"
            />

            <div
              class="program-detail-specialists-images"
              @click="handleNavigateToAvailableSpecialist"
            >
              <div class="program-detail-specialists-images-container">
                <img
                  v-for="(item, index) in specialistImages"
                  :key="index"
                  :src="item"
                />
              </div>
              <div class="heading-mate text-gray-50 icon-specialists-off" />
              <div class="body text-gray-80" v-text="specialistResult" />
              <div
                class="content-title cursor-pointer text-gray-80 program-detail-specialists-images-container-button"
                @click="handleNavigateToAvailableSpecialist"
                v-text="$translations.programs.detail.specialists.profile"
              />
            </div>

            <div
              class="body margin-top"
              v-text="$translations.programs.detail.specialists.description"
            />
          </div>
        </div>

        <!-- RELATED PROGRAMS -->
        <!-- TODO -->
        <!-- <div class="page-segment">
          <div class="page-segment-content">
            <div
              class="heading-small"
              v-text="$translations.programs.detail.related"
            />
          </div>
        </div> -->

        <!-- TYPEFORM -->
        <div class="page-segment">
          <div class="page-segment-content text-center">
            <div
              class="body-big"
              v-html="$translations.programs.detail.question.title"
            />
            <Button
              type="link"
              text-color="purple"
              :text="$translations.programs.detail.question.button"
              @clicked="goToTypeform"
            />
          </div>
        </div>

        <!-- QUESTIONS -->
        <div class="segment">
          <div class="segment-content">
            <div
              class="small-tag"
              v-text="$translations['appointment-summary'].faq"
            />
            <Accordion
              v-for="(question, key) in faq"
              :key="key"
              :text="question.title[lang]"
              :hasCustomContent="true"
              :is-collapsed="currentFaqIndex !== key"
              :custom-controller="true"
              @collapse="changeCurrentFaqIndex($event, key)"
            >
              <template v-slot:accordion>
                <div v-html="question.description[lang]"></div>
              </template>
            </Accordion>
          </div>
        </div>
      </template>
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  NumberFilter,
  StatItem,
  Button,
  Settings,
  Accordion,
  Skeleton,
  ResponsiveMixin
} from '@seliaco/red-panda'
import AddiBanner from '@/components/banner/AddiBanner'
import countryValidation from '@/mixins/country-validation'
import { Browser } from '@capacitor/browser'
import { mapGetters } from 'vuex'

export default {
  name: 'ProgramDetail',
  components: {
    PageContainer,
    StatItem,
    Button,
    Accordion,
    Skeleton,
    AddiBanner
  },
  mixins: [countryValidation, ResponsiveMixin],
  data () {
    const lang = this.$store.getters.language.lang

    const pageContainerConfig = {
      title: this.$translations.programs.detail.title,
      back: {
        callback: () => {
          if (this.$route.query.back) {
            this.$router.replace({
              path: this.$route.query.back
            })
          } else {
            this.$router.push({ name: 'Home' })
          }
        }
      },
      submit: {
        callback: () => this.handlerCheckout(),
        text: this.$translations.programs.detail.submit,
        loading: false,
        disabled: false
      }
    }

    return {
      lang,
      pageContainerConfig,
      toggleDescription: false,
      faq: [],
      currentFaqIndex: null
    }
  },
  mounted () {
    this.getFAQ()
    this.getProgram()
  },
  methods: {
    getProgram () {
      this.$store.dispatch('programs/getProgramId', {
        id: this.$route.params.id
      })
    },
    getFAQ () {
      Settings.read({
        columns: 'value',
        eq: {
          key: 'FAQ_PROGRAMS'
        }
      }).then((response) => {
        if (response.data[0].value) {
          this.faq = JSON.parse(response.data[0].value)
        }
      })
    },
    handlerCheckout (additionalData) {
      this.pageContainerConfig.submit.loading = true
      let query = {
        back: this.$route.fullPath
      }

      if (additionalData) {
        query = {
          ...query,
          paymentMethodType: additionalData
        }
      }

      this.$router.push({
        name: 'CheckOut',
        params: {
          id: this.program.id,
          type: 'PROGRAM'
        },
        query
      })
    },
    navigateToSpecialistProfile () {
      this.$router.push({
        name: 'SpecialistDetail',
        params: {
          id: this.program?.specialist.id
        },
        query: {
          back: this.$route.fullPath
        }
      })
    },
    goToTypeform () {
      Settings.get('SELIA_USERS_PROGRAMS_FORM_URL')
        .then((value) => {
          const url = value.parse_value.replaceAll('{USER_ID}', this.user.id)

          Browser.open({ url }).catch((e) => {
            this.$toast({
              text: e.message || this.$translations.error.default,
              severity: 'error'
            })
          })
        })
        .catch((e) => {
          this.$toast({
            text: e.message,
            severity: 'error'
          })
        })
    },
    handleNavigateToAvailableSpecialist () {
      this.$router.push({
        name: 'SpecialistsList',
        query: {
          title: this.$translations.programs.detail.specialists.available,
          back: this.$route.fullPath,
          specialistSource: JSON.stringify({
            id: this.program.id,
            store: 'programs',
            action: 'getProgramSpecialists',
            getter: 'getProgramSpecialists'
          })
        }
      })
    },
    changeCurrentFaqIndex (value, key) {
      this.currentFaqIndex = value ? key : null
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      program: 'programs/getProgram',
      loading: 'programs/getLoading',
      specialists: 'programs/getProgramSpecialists'
    }),
    programPrice () {
      const price = this.program.price || 0
      const priceFinal = NumberFilter(
        price / 100,
        this.$store.getters.language.lang,
        this.user.country
      )

      return `${priceFinal} ${this.program.currency?.toUpperCase() || ''}`
    },
    specialistImages () {
      const avatar = require('@/assets/images/avatar.png')
      const images = this.specialists.map((specialist) => {
        return specialist?.user?.profile_picture
          ? encodeURI(specialist.user.profile_picture)
          : avatar
      })

      return images.slice(0, 3)
    },
    specialistResult () {
      return `${
        this.specialists?.length
      } ${this.$translations.programs.detail.specialists.title.toLowerCase()}`
    },
    descriptionMoreAndLess () {
      if (!this.program) {
        return ''
      }

      return this.program.description.length > 200 && !this.toggleDescription
        ? this.program.description.slice(0, -100) + '...'
        : this.program.description
    },
    getText () {
      return this.toggleDescription
        ? this.$translations.general['see-less']
        : this.$translations.general['see-more']
    }
  }
}
</script>

<style lang="sass" scoped>
.program-detail
  &-cover
    position: relative
    &::before
      content: ''
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
      z-index: 1
    &-img
      height: 240px
      width: 100%
      object-fit: cover

  &-items
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 30px 16px
    margin-bottom: 16px

  &-learning
    &-content
      display: flex
      gap: 10px
      align-items: center
      margin-bottom: 8px

  &-specialists
    &-images
      display: flex
      align-items: center
      gap: 7px
      margin-top: 22px
      &-container
        display: flex
        padding-left: 16px
        img
          width: 32px
          min-width: 32px
          height: 32px
          border-radius: 50%
          border: 2px solid var(--white)
          margin-left: -12px
          object-fit: cover
        &-button
          margin-left: auto

@media (min-width: 1040px)
  .program-detail-cover
    &-content
      left: calc((1040px - 480px)/2 + 20px)
      width: 480px
    img,
    &::before
      border-radius: 16px 16px 0 0
</style>
